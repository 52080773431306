<svg
  [attr.width]="width"
  [attr.height]="height"
  fill="none"
  [attr.stroke]="color || 'currentColor'"
  [attr.stroke-width]="strokeWidth"
  stroke-linecap="round"
  stroke-linejoin="round">
  <use attr.href="/assets/svg/feather-sprite.svg#{{ type }}" />
</svg>
