import { environment } from '../../../environments/environment';
const baseUrl = environment.BASE_URL;
const apiVersion = 'api/v1';
const nodeId = '1fa85f64-5717-4562-b3fc-2c963f66afa2';
const projectId = '1fa85f64-5717-4562-b3fc-2c963f66afa1';

const proPortalnodeIdAlt = '4a198102-5829-4390-bc69-88f0fa8fae1b';
const proPortalprojectId = '302fe854-7640-49c9-8009-49bba673d269';
const proPortalcustomerId = 'd9e25676-e710-4d52-937c-92a957f75c22';

const API_BASE_URL = `${baseUrl}/${apiVersion}/node/${nodeId}/project/${projectId}`;
const PRO_PORTAL_API_BASE_URL = `${baseUrl}/${apiVersion}/node/${proPortalnodeIdAlt}/project/${proPortalprojectId}/customer/${proPortalcustomerId}`;

export const serviceUrl: any = {
  apiBaseURL: API_BASE_URL,
  proPortalApiBaseURL: PRO_PORTAL_API_BASE_URL,
  evaluationExists: `${API_BASE_URL}/evaluation/exists`,
  evaluation: `${API_BASE_URL}/evaluation`,
  getMasterDataForEvaluation: `${PRO_PORTAL_API_BASE_URL}/evaluation/master`,
  deleteStage: `${API_BASE_URL}/evaluation/stage`,
  deleteSection: `${API_BASE_URL}/evaluation/section`,
  deleteCriteria: `${API_BASE_URL}/evaluation/criteria`,
  proposalResponse: `${PRO_PORTAL_API_BASE_URL}/proposalresponse`,
  saveBuildFlow: `${API_BASE_URL}/evaluation/autosave`,
  getTeamMembersList: `${PRO_PORTAL_API_BASE_URL}/teammembers`,
  getEvaluatorsList: `${PRO_PORTAL_API_BASE_URL}/evaluation`,
};
