import { Component, OnDestroy, OnInit } from '@angular/core';
import { PageLoaderService } from '../../services/page-loader.service';
import { takeWhile } from 'rxjs';

@Component({
  selector: 'app-page-loader',
  templateUrl: './page-loader.component.html',
  styleUrls: ['./page-loader.component.scss'],
})
export class PageLoaderComponent implements OnInit, OnDestroy {
  isLoading = false;
  private _subscribed = true;
  constructor(private pageLoader: PageLoaderService) {}
  ngOnInit(): void {
    this.subscribePageLoader();
  }
  subscribePageLoader() {
    this.pageLoader.state
      .pipe(takeWhile(() => this._subscribed))
      .subscribe(loading => {
        this.isLoading = loading;
      });
  }

  ngOnDestroy(): void {
    this._subscribed = false;
  }
}
