import { Component, Input, OnInit } from '@angular/core';
import { PageLoaderService } from 'src/app/core/services/page-loader.service';
import { ReviewMeetingService } from 'src/app/core/services/review-meeting/review-meeting.service';
import { StoreService } from 'src/app/core/services/store.service';
@Component({
  selector: 'app-review-grid',
  templateUrl: './review-grid.component.html',
  styleUrls: ['./review-grid.component.scss'],
})
export class ReviewGridComponent implements OnInit {
  constructor(
    private pageLoader: PageLoaderService,
    private reviwMeetingService: ReviewMeetingService,
    private store: StoreService
  ) {}

  @Input() tooltipHeader = 'Lack of Consensus Detected';
  @Input() tooltipText =
    'Deviation Percentage: 10%Allowable Range: Scores must be within 0.2 points of the consensus scoreDeviation: Evaluator score is 0.8 points below the allowable range, causing lack of consensus.';
  showReviewGrid = false;
  isSupplierView = false;
  suppliersList: any[] = [];
  stagesList: any[] = [];
  evaluatorList: any[] = [];
  scoreCardData: any = null;
  selectedStageId = '';
  selectedSupplierId = '';
  selectedSupplierIndex = 0;

  paginationItemStartIndex = 1;
  paginationItemEndIndex = 1;

  currentPage = 1;
  itemsPerPage = this.isSupplierView ? 4 : 2;

  get displayedItems() {
    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;
    return this.isSupplierView
      ? this.suppliersList?.slice(startIndex, endIndex)
      : this.evaluatorList?.slice(startIndex, endIndex);
  }

  get displayedStageItems() {
    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;
    return this.isSupplierView
      ? this.scoreCardData?.selectedStage?.supplierScores?.slice(
          startIndex,
          endIndex
        )
      : this.scoreCardData?.selectedStage?.evaluatorScores?.slice(
          startIndex,
          endIndex
        );
  }
  displayedSectionCriteriaItems(item: any) {
    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;
    return this.isSupplierView
      ? item?.supplierScores?.slice(startIndex, endIndex)
      : item?.evaluatorScores?.slice(startIndex, endIndex);
  }

  nextPage() {
    if (
      this.currentPage * this.itemsPerPage <
      (this.isSupplierView
        ? this.suppliersList.length
        : this.evaluatorList.length)
    ) {
      this.currentPage++;
    }
  }

  previousPage() {
    if (this.currentPage > 1) {
      this.currentPage--;
    }
  }

  get currentRange() {
    const start = (this.currentPage - 1) * this.itemsPerPage + 1;
    const end = Math.min(
      this.currentPage * this.itemsPerPage,
      this.isSupplierView
        ? this.suppliersList?.length
        : this.evaluatorList?.length
    );
    return `${start}-${end} of ${
      this.isSupplierView
        ? this.suppliersList?.length
        : this.evaluatorList?.length
    }`;
  }

  ngOnInit(): void {
    this.getScorecardDetails();
  }

  setDefaultList() {
    if (this.stagesList && this.stagesList.length > 0) {
      console.log('test');
      this.selectedStageId = this.stagesList[0].id;
    }
    if (this.suppliersList && this.suppliersList.length > 0) {
      this.selectedSupplierId = this.suppliersList[0].supplierId;
    }
  }

  changeView() {
    this.isSupplierView = !this.isSupplierView;
    this.currentPage = 1;
    this.itemsPerPage = this.isSupplierView ? 4 : 2;
  }

  getSectionAndCriteriasList(sections: any, criterias: any) {
    return [...sections, ...criterias]
      .sort((a, b) => a.displayOrder - b.displayOrder)
      .slice();
  }

  getScorecardDetails() {
    // this.scoreCardData = {
    //   evaluationMeetingPermissionId: 1,
    //   evaluationMeetingPermission: 'Procurement Lead Only',
    //   deviationPercentage: 10,
    //   evaluationMeetingStartDateTime: '2025-02-04T03:50:15.984304Z',
    //   selectedStage: {
    //     id: '8c32d6ac-062a-4ea1-afbf-d25b12c50dcb',
    //     title: 'Stage1',
    //     expectedWeightedScore: 35.0,
    //     actualWeightedScore: 9.0,
    //     stageTypeId: 1,
    //     minThreshold: 10.0,
    //     evaluationId: '074bc8d8-9cd3-4a15-9a10-01b1ae705023',
    //     displayOrder: 1,
    //     evaluatorScores: [
    //       {
    //         evaluatorId: '1ef97012-5fd8-4ce9-9b03-52305e37db30',
    //         evaluatorName: 'Chetan Jadhav',
    //         evaluatorScore: 11.0,
    //         hasConsensus: false,
    //         allowableRange: null,
    //         deviation: null,
    //       },
    //       {
    //         evaluatorId: 'e301800e-c70d-4852-aba9-be8db2aa6611',
    //         evaluatorName: 'Preethi Preethi',
    //         evaluatorScore: 6.0,
    //         hasConsensus: false,
    //         allowableRange: null,
    //         deviation: null,
    //       },
    //       {
    //         evaluatorId: 'a3d836de-6123-4400-a945-97e0c25bfa30',
    //         evaluatorName: 'siddharth Gupta',
    //         evaluatorScore: 5.0,
    //         hasConsensus: false,
    //         allowableRange: null,
    //         deviation: null,
    //       },
    //     ],
    //     supplierScores: [
    //       {
    //         supplierId: '93d122f9-dc04-4af1-8b0e-fda4ec034bd9',
    //         supplierName: 'Albert Company',
    //         submissionId: '2c7ad4f3-16df-4a5d-b598-4539457f8e51',
    //         submissionName: null,
    //         expectedScore: 35.0,
    //         actualScore: 9.0,
    //         hasConsensus: false,
    //         allowableRange: null,
    //         deviation: null,
    //       },
    //       {
    //         supplierId: 'fca9307f-93d3-42c8-a5f8-aa71c85e31de',
    //         supplierName: 'Bomber Company',
    //         submissionId: '8904f83f-4091-46ea-9399-161ad5ac92c9',
    //         submissionName: null,
    //         expectedScore: 35.0,
    //         actualScore: 4.5,
    //         hasConsensus: false,
    //         allowableRange: null,
    //         deviation: null,
    //       },
    //       {
    //         supplierId: '9436f713-a91a-43e9-a332-ae36d4ceed36',
    //         supplierName: 'Cat Company',
    //         submissionId: 'fb8ce4b3-1161-45a0-9c4b-1b6892f4a36b',
    //         submissionName: null,
    //         expectedScore: 35.0,
    //         actualScore: 0.0,
    //         hasConsensus: false,
    //         allowableRange: null,
    //         deviation: null,
    //       },
    //       {
    //         supplierId: '96f92115-7227-4560-acf4-c3c80070cef4',
    //         supplierName: 'Daylight Company',
    //         submissionId: '815db372-f5af-40b5-8250-16a106302a6a',
    //         submissionName: null,
    //         expectedScore: 35.0,
    //         actualScore: 0.0,
    //         hasConsensus: false,
    //         allowableRange: null,
    //         deviation: null,
    //       },
    //       {
    //         supplierId: '9e993caa-b78a-4707-aa26-d8f0871a8f93',
    //         supplierName: 'Electronic Company',
    //         submissionId: '0f04edf5-a558-4238-ba1f-ce3091deeb1e',
    //         submissionName: null,
    //         expectedScore: 35.0,
    //         actualScore: 0.0,
    //         hasConsensus: false,
    //         allowableRange: null,
    //         deviation: null,
    //       },
    //     ],
    //     sections: [
    //       {
    //         id: '74e0f054-44d9-4819-ae9e-01ad941494bf',
    //         evaluationStageId: '8c32d6ac-062a-4ea1-afbf-d25b12c50dcb',
    //         title: 'SECTION1 - stage1',
    //         expectedWeightedScore: 20.0,
    //         actualWeightedScore: 7.25,
    //         displayOrder: 1,
    //         evaluatorScores: [
    //           {
    //             evaluatorId: '1ef97012-5fd8-4ce9-9b03-52305e37db30',
    //             evaluatorName: 'Chetan Jadhav',
    //             evaluatorScore: 6.0,
    //             hasConsensus: false,
    //             allowableRange: null,
    //             deviation: null,
    //           },
    //           {
    //             evaluatorId: 'e301800e-c70d-4852-aba9-be8db2aa6611',
    //             evaluatorName: 'Preethi Preethi',
    //             evaluatorScore: 4.0,
    //             hasConsensus: false,
    //             allowableRange: null,
    //             deviation: null,
    //           },
    //           {
    //             evaluatorId: 'a3d836de-6123-4400-a945-97e0c25bfa30',
    //             evaluatorName: 'siddharth Gupta',
    //             evaluatorScore: 5.0,
    //             hasConsensus: false,
    //             allowableRange: null,
    //             deviation: null,
    //           },
    //         ],
    //         supplierScores: [
    //           {
    //             supplierId: '93d122f9-dc04-4af1-8b0e-fda4ec034bd9',
    //             supplierName: 'Albert Company',
    //             submissionId: '2c7ad4f3-16df-4a5d-b598-4539457f8e51',
    //             submissionName: null,
    //             expectedScore: 20.0,
    //             actualScore: 7.25,
    //             hasConsensus: false,
    //             allowableRange: null,
    //             deviation: null,
    //           },
    //           {
    //             supplierId: 'fca9307f-93d3-42c8-a5f8-aa71c85e31de',
    //             supplierName: 'Bomber Company',
    //             submissionId: '8904f83f-4091-46ea-9399-161ad5ac92c9',
    //             submissionName: null,
    //             expectedScore: 20.0,
    //             actualScore: 4.5,
    //             hasConsensus: false,
    //             allowableRange: null,
    //             deviation: null,
    //           },
    //           {
    //             supplierId: '9436f713-a91a-43e9-a332-ae36d4ceed36',
    //             supplierName: 'Cat Company',
    //             submissionId: 'fb8ce4b3-1161-45a0-9c4b-1b6892f4a36b',
    //             submissionName: null,
    //             expectedScore: 20.0,
    //             actualScore: 0.0,
    //             hasConsensus: false,
    //             allowableRange: null,
    //             deviation: null,
    //           },
    //           {
    //             supplierId: '96f92115-7227-4560-acf4-c3c80070cef4',
    //             supplierName: 'Daylight Company',
    //             submissionId: '815db372-f5af-40b5-8250-16a106302a6a',
    //             submissionName: null,
    //             expectedScore: 20.0,
    //             actualScore: 0.0,
    //             hasConsensus: false,
    //             allowableRange: null,
    //             deviation: null,
    //           },
    //           {
    //             supplierId: '9e993caa-b78a-4707-aa26-d8f0871a8f93',
    //             supplierName: 'Electronic Company',
    //             submissionId: '0f04edf5-a558-4238-ba1f-ce3091deeb1e',
    //             submissionName: null,
    //             expectedScore: 20.0,
    //             actualScore: 0.0,
    //             hasConsensus: false,
    //             allowableRange: null,
    //             deviation: null,
    //           },
    //         ],
    //         criterias: [
    //           {
    //             id: 'd47c1537-6172-4d50-80ca-4cc124e6222a',
    //             parentId: '74e0f054-44d9-4819-ae9e-01ad941494bf',
    //             title: 'Criteria1 - section1 - stage1',
    //             minRatingScale: 0,
    //             maxRatingScale: 10,
    //             expectedWeightedScore: 15.0,
    //             actualWeightedScore: 5.25,
    //             isOverwrittenWeightedScore: false,
    //             weightScoreAdjusted: null,
    //             displayOrder: 1,
    //             proposalResponseTypes: [
    //               'Document Upload',
    //               'Questions',
    //               'Schedule Prices',
    //             ],
    //             attachmentCount: 2,
    //             evaluatorScores: [
    //               {
    //                 evaluatorId: '1ef97012-5fd8-4ce9-9b03-52305e37db30',
    //                 evaluatorName: 'Chetan Jadhav',
    //                 evaluatorScore: 3.0,
    //                 hasConsensus: false,
    //                 allowableRange: null,
    //                 deviation: null,
    //               },
    //               {
    //                 evaluatorId: 'e301800e-c70d-4852-aba9-be8db2aa6611',
    //                 evaluatorName: 'Preethi Preethi',
    //                 evaluatorScore: 4.0,
    //                 hasConsensus: false,
    //                 allowableRange: null,
    //                 deviation: null,
    //               },
    //             ],
    //             supplierScores: [
    //               {
    //                 supplierId: '93d122f9-dc04-4af1-8b0e-fda4ec034bd9',
    //                 supplierName: 'Albert Company',
    //                 submissionId: '2c7ad4f3-16df-4a5d-b598-4539457f8e51',
    //                 submissionName: null,
    //                 expectedScore: 15.0,
    //                 actualScore: 5.25,
    //                 hasConsensus: false,
    //                 allowableRange: null,
    //                 deviation: null,
    //               },
    //               {
    //                 supplierId: 'fca9307f-93d3-42c8-a5f8-aa71c85e31de',
    //                 supplierName: 'Bomber Company',
    //                 submissionId: '8904f83f-4091-46ea-9399-161ad5ac92c9',
    //                 submissionName: null,
    //                 expectedScore: 15.0,
    //                 actualScore: 4.5,
    //                 hasConsensus: false,
    //                 allowableRange: null,
    //                 deviation: null,
    //               },
    //               {
    //                 supplierId: '9436f713-a91a-43e9-a332-ae36d4ceed36',
    //                 supplierName: 'Cat Company',
    //                 submissionId: 'fb8ce4b3-1161-45a0-9c4b-1b6892f4a36b',
    //                 submissionName: null,
    //                 expectedScore: 15.0,
    //                 actualScore: 0.0,
    //                 hasConsensus: false,
    //                 allowableRange: null,
    //                 deviation: null,
    //               },
    //               {
    //                 supplierId: '96f92115-7227-4560-acf4-c3c80070cef4',
    //                 supplierName: 'Daylight Company',
    //                 submissionId: '815db372-f5af-40b5-8250-16a106302a6a',
    //                 submissionName: null,
    //                 expectedScore: 15.0,
    //                 actualScore: 0.0,
    //                 hasConsensus: false,
    //                 allowableRange: null,
    //                 deviation: null,
    //               },
    //               {
    //                 supplierId: '9e993caa-b78a-4707-aa26-d8f0871a8f93',
    //                 supplierName: 'Electronic Company',
    //                 submissionId: '0f04edf5-a558-4238-ba1f-ce3091deeb1e',
    //                 submissionName: null,
    //                 expectedScore: 15.0,
    //                 actualScore: 0.0,
    //                 hasConsensus: false,
    //                 allowableRange: null,
    //                 deviation: null,
    //               },
    //             ],
    //           },
    //           {
    //             id: '228243f4-f89c-4ed0-92fd-143e4b65f2e8',
    //             parentId: '74e0f054-44d9-4819-ae9e-01ad941494bf',
    //             title: 'Criteria2 - section1 - stage1',
    //             minRatingScale: 0,
    //             maxRatingScale: 10,
    //             expectedWeightedScore: 5.0,
    //             actualWeightedScore: 2.0,
    //             isOverwrittenWeightedScore: false,
    //             weightScoreAdjusted: null,
    //             displayOrder: 2,
    //             proposalResponseTypes: ['Document Upload'],
    //             attachmentCount: 0,
    //             evaluatorScores: [
    //               {
    //                 evaluatorId: '1ef97012-5fd8-4ce9-9b03-52305e37db30',
    //                 evaluatorName: 'Chetan Jadhav',
    //                 evaluatorScore: 3.0,
    //                 hasConsensus: false,
    //                 allowableRange: null,
    //                 deviation: null,
    //               },
    //               {
    //                 evaluatorId: 'a3d836de-6123-4400-a945-97e0c25bfa30',
    //                 evaluatorName: 'siddharth Gupta',
    //                 evaluatorScore: 5.0,
    //                 hasConsensus: false,
    //                 allowableRange: null,
    //                 deviation: null,
    //               },
    //             ],
    //             supplierScores: [
    //               {
    //                 supplierId: '93d122f9-dc04-4af1-8b0e-fda4ec034bd9',
    //                 supplierName: 'Albert Company',
    //                 submissionId: '2c7ad4f3-16df-4a5d-b598-4539457f8e51',
    //                 submissionName: null,
    //                 expectedScore: 5.0,
    //                 actualScore: 2.0,
    //                 hasConsensus: false,
    //                 allowableRange: null,
    //                 deviation: null,
    //               },
    //               {
    //                 supplierId: 'fca9307f-93d3-42c8-a5f8-aa71c85e31de',
    //                 supplierName: 'Bomber Company',
    //                 submissionId: '8904f83f-4091-46ea-9399-161ad5ac92c9',
    //                 submissionName: null,
    //                 expectedScore: 5.0,
    //                 actualScore: 0.0,
    //                 hasConsensus: false,
    //                 allowableRange: null,
    //                 deviation: null,
    //               },
    //               {
    //                 supplierId: '9436f713-a91a-43e9-a332-ae36d4ceed36',
    //                 supplierName: 'Cat Company',
    //                 submissionId: 'fb8ce4b3-1161-45a0-9c4b-1b6892f4a36b',
    //                 submissionName: null,
    //                 expectedScore: 5.0,
    //                 actualScore: 0.0,
    //                 hasConsensus: false,
    //                 allowableRange: null,
    //                 deviation: null,
    //               },
    //               {
    //                 supplierId: '96f92115-7227-4560-acf4-c3c80070cef4',
    //                 supplierName: 'Daylight Company',
    //                 submissionId: '815db372-f5af-40b5-8250-16a106302a6a',
    //                 submissionName: null,
    //                 expectedScore: 5.0,
    //                 actualScore: 0.0,
    //                 hasConsensus: false,
    //                 allowableRange: null,
    //                 deviation: null,
    //               },
    //               {
    //                 supplierId: '9e993caa-b78a-4707-aa26-d8f0871a8f93',
    //                 supplierName: 'Electronic Company',
    //                 submissionId: '0f04edf5-a558-4238-ba1f-ce3091deeb1e',
    //                 submissionName: null,
    //                 expectedScore: 5.0,
    //                 actualScore: 0.0,
    //                 hasConsensus: false,
    //                 allowableRange: null,
    //                 deviation: null,
    //               },
    //             ],
    //           },
    //         ],
    //       },
    //       {
    //         id: 'c4d8da4d-b676-459a-95ad-b86a151ce441',
    //         evaluationStageId: '8c32d6ac-062a-4ea1-afbf-d25b12c50dcb',
    //         title: 'SECTION2 - stage1',
    //         expectedWeightedScore: 10.0,
    //         actualWeightedScore: 0.0,
    //         displayOrder: 3,
    //         evaluatorScores: [],
    //         supplierScores: [
    //           {
    //             supplierId: '93d122f9-dc04-4af1-8b0e-fda4ec034bd9',
    //             supplierName: 'Albert Company',
    //             submissionId: '2c7ad4f3-16df-4a5d-b598-4539457f8e51',
    //             submissionName: null,
    //             expectedScore: 10.0,
    //             actualScore: 0.0,
    //             hasConsensus: false,
    //             allowableRange: null,
    //             deviation: null,
    //           },
    //           {
    //             supplierId: 'fca9307f-93d3-42c8-a5f8-aa71c85e31de',
    //             supplierName: 'Bomber Company',
    //             submissionId: '8904f83f-4091-46ea-9399-161ad5ac92c9',
    //             submissionName: null,
    //             expectedScore: 10.0,
    //             actualScore: 0.0,
    //             hasConsensus: false,
    //             allowableRange: null,
    //             deviation: null,
    //           },
    //           {
    //             supplierId: '9436f713-a91a-43e9-a332-ae36d4ceed36',
    //             supplierName: 'Cat Company',
    //             submissionId: 'fb8ce4b3-1161-45a0-9c4b-1b6892f4a36b',
    //             submissionName: null,
    //             expectedScore: 10.0,
    //             actualScore: 0.0,
    //             hasConsensus: false,
    //             allowableRange: null,
    //             deviation: null,
    //           },
    //           {
    //             supplierId: '96f92115-7227-4560-acf4-c3c80070cef4',
    //             supplierName: 'Daylight Company',
    //             submissionId: '815db372-f5af-40b5-8250-16a106302a6a',
    //             submissionName: null,
    //             expectedScore: 10.0,
    //             actualScore: 0.0,
    //             hasConsensus: false,
    //             allowableRange: null,
    //             deviation: null,
    //           },
    //           {
    //             supplierId: '9e993caa-b78a-4707-aa26-d8f0871a8f93',
    //             supplierName: 'Electronic Company',
    //             submissionId: '0f04edf5-a558-4238-ba1f-ce3091deeb1e',
    //             submissionName: null,
    //             expectedScore: 10.0,
    //             actualScore: 0.0,
    //             hasConsensus: false,
    //             allowableRange: null,
    //             deviation: null,
    //           },
    //         ],
    //         criterias: [
    //           {
    //             id: '5de12515-23a0-46e8-a6d9-6f9259bde217',
    //             parentId: 'c4d8da4d-b676-459a-95ad-b86a151ce441',
    //             title: 'Criteria1 - section2 - stage1',
    //             minRatingScale: 0,
    //             maxRatingScale: 10,
    //             expectedWeightedScore: 7.0,
    //             actualWeightedScore: 0.0,
    //             isOverwrittenWeightedScore: false,
    //             weightScoreAdjusted: null,
    //             displayOrder: 1,
    //             proposalResponseTypes: ['Document Upload'],
    //             attachmentCount: 0,
    //             evaluatorScores: null,
    //             supplierScores: [
    //               {
    //                 supplierId: '93d122f9-dc04-4af1-8b0e-fda4ec034bd9',
    //                 supplierName: 'Albert Company',
    //                 submissionId: '2c7ad4f3-16df-4a5d-b598-4539457f8e51',
    //                 submissionName: null,
    //                 expectedScore: 7.0,
    //                 actualScore: 0.0,
    //                 hasConsensus: false,
    //                 allowableRange: null,
    //                 deviation: null,
    //               },
    //               {
    //                 supplierId: 'fca9307f-93d3-42c8-a5f8-aa71c85e31de',
    //                 supplierName: 'Bomber Company',
    //                 submissionId: '8904f83f-4091-46ea-9399-161ad5ac92c9',
    //                 submissionName: null,
    //                 expectedScore: 7.0,
    //                 actualScore: 0.0,
    //                 hasConsensus: false,
    //                 allowableRange: null,
    //                 deviation: null,
    //               },
    //               {
    //                 supplierId: '9436f713-a91a-43e9-a332-ae36d4ceed36',
    //                 supplierName: 'Cat Company',
    //                 submissionId: 'fb8ce4b3-1161-45a0-9c4b-1b6892f4a36b',
    //                 submissionName: null,
    //                 expectedScore: 7.0,
    //                 actualScore: 0.0,
    //                 hasConsensus: false,
    //                 allowableRange: null,
    //                 deviation: null,
    //               },
    //               {
    //                 supplierId: '96f92115-7227-4560-acf4-c3c80070cef4',
    //                 supplierName: 'Daylight Company',
    //                 submissionId: '815db372-f5af-40b5-8250-16a106302a6a',
    //                 submissionName: null,
    //                 expectedScore: 7.0,
    //                 actualScore: 0.0,
    //                 hasConsensus: false,
    //                 allowableRange: null,
    //                 deviation: null,
    //               },
    //               {
    //                 supplierId: '9e993caa-b78a-4707-aa26-d8f0871a8f93',
    //                 supplierName: 'Electronic Company',
    //                 submissionId: '0f04edf5-a558-4238-ba1f-ce3091deeb1e',
    //                 submissionName: null,
    //                 expectedScore: 7.0,
    //                 actualScore: 0.0,
    //                 hasConsensus: false,
    //                 allowableRange: null,
    //                 deviation: null,
    //               },
    //             ],
    //           },
    //           {
    //             id: '42a4e9c6-f255-4283-a3ef-fbd4acf2381e',
    //             parentId: 'c4d8da4d-b676-459a-95ad-b86a151ce441',
    //             title: 'Criteria2 - section2 - stage1',
    //             minRatingScale: 0,
    //             maxRatingScale: 10,
    //             expectedWeightedScore: 3.0,
    //             actualWeightedScore: 0.0,
    //             isOverwrittenWeightedScore: false,
    //             weightScoreAdjusted: null,
    //             displayOrder: 2,
    //             proposalResponseTypes: [],
    //             attachmentCount: 0,
    //             evaluatorScores: null,
    //             supplierScores: [
    //               {
    //                 supplierId: '93d122f9-dc04-4af1-8b0e-fda4ec034bd9',
    //                 supplierName: 'Albert Company',
    //                 submissionId: '2c7ad4f3-16df-4a5d-b598-4539457f8e51',
    //                 submissionName: null,
    //                 expectedScore: 3.0,
    //                 actualScore: 0.0,
    //                 hasConsensus: false,
    //                 allowableRange: null,
    //                 deviation: null,
    //               },
    //               {
    //                 supplierId: 'fca9307f-93d3-42c8-a5f8-aa71c85e31de',
    //                 supplierName: 'Bomber Company',
    //                 submissionId: '8904f83f-4091-46ea-9399-161ad5ac92c9',
    //                 submissionName: null,
    //                 expectedScore: 3.0,
    //                 actualScore: 0.0,
    //                 hasConsensus: false,
    //                 allowableRange: null,
    //                 deviation: null,
    //               },
    //               {
    //                 supplierId: '9436f713-a91a-43e9-a332-ae36d4ceed36',
    //                 supplierName: 'Cat Company',
    //                 submissionId: 'fb8ce4b3-1161-45a0-9c4b-1b6892f4a36b',
    //                 submissionName: null,
    //                 expectedScore: 3.0,
    //                 actualScore: 0.0,
    //                 hasConsensus: false,
    //                 allowableRange: null,
    //                 deviation: null,
    //               },
    //               {
    //                 supplierId: '96f92115-7227-4560-acf4-c3c80070cef4',
    //                 supplierName: 'Daylight Company',
    //                 submissionId: '815db372-f5af-40b5-8250-16a106302a6a',
    //                 submissionName: null,
    //                 expectedScore: 3.0,
    //                 actualScore: 0.0,
    //                 hasConsensus: false,
    //                 allowableRange: null,
    //                 deviation: null,
    //               },
    //               {
    //                 supplierId: '9e993caa-b78a-4707-aa26-d8f0871a8f93',
    //                 supplierName: 'Electronic Company',
    //                 submissionId: '0f04edf5-a558-4238-ba1f-ce3091deeb1e',
    //                 submissionName: null,
    //                 expectedScore: 3.0,
    //                 actualScore: 0.0,
    //                 hasConsensus: false,
    //                 allowableRange: null,
    //                 deviation: null,
    //               },
    //             ],
    //           },
    //         ],
    //       },
    //     ],
    //     criterias: [
    //       {
    //         id: 'e065a9b9-2826-48d0-94ac-af39a66c7302',
    //         parentId: '8c32d6ac-062a-4ea1-afbf-d25b12c50dcb',
    //         title: 'Criteria1 - stage1',
    //         minRatingScale: 0,
    //         maxRatingScale: 10,
    //         expectedWeightedScore: 5.0,
    //         actualWeightedScore: 1.75,
    //         isOverwrittenWeightedScore: false,
    //         weightScoreAdjusted: null,
    //         displayOrder: 2,
    //         proposalResponseTypes: ['Document Upload'],
    //         attachmentCount: 0,
    //         evaluatorScores: [
    //           {
    //             evaluatorId: '1ef97012-5fd8-4ce9-9b03-52305e37db30',
    //             evaluatorName: 'Chetan Jadhav',
    //             evaluatorScore: 5.0,
    //             hasConsensus: false,
    //             allowableRange: null,
    //             deviation: null,
    //           },
    //           {
    //             evaluatorId: 'e301800e-c70d-4852-aba9-be8db2aa6611',
    //             evaluatorName: 'Preethi Preethi',
    //             evaluatorScore: 2.0,
    //             hasConsensus: false,
    //             allowableRange: null,
    //             deviation: null,
    //           },
    //         ],
    //         supplierScores: [
    //           {
    //             supplierId: '93d122f9-dc04-4af1-8b0e-fda4ec034bd9',
    //             supplierName: 'Albert Company',
    //             submissionId: '2c7ad4f3-16df-4a5d-b598-4539457f8e51',
    //             submissionName: null,
    //             expectedScore: 5.0,
    //             actualScore: 1.75,
    //             hasConsensus: false,
    //             allowableRange: null,
    //             deviation: null,
    //           },
    //           {
    //             supplierId: 'fca9307f-93d3-42c8-a5f8-aa71c85e31de',
    //             supplierName: 'Bomber Company',
    //             submissionId: '8904f83f-4091-46ea-9399-161ad5ac92c9',
    //             submissionName: null,
    //             expectedScore: 5.0,
    //             actualScore: 0.0,
    //             hasConsensus: false,
    //             allowableRange: null,
    //             deviation: null,
    //           },
    //           {
    //             supplierId: '9436f713-a91a-43e9-a332-ae36d4ceed36',
    //             supplierName: 'Cat Company',
    //             submissionId: 'fb8ce4b3-1161-45a0-9c4b-1b6892f4a36b',
    //             submissionName: null,
    //             expectedScore: 5.0,
    //             actualScore: 0.0,
    //             hasConsensus: false,
    //             allowableRange: null,
    //             deviation: null,
    //           },
    //           {
    //             supplierId: '96f92115-7227-4560-acf4-c3c80070cef4',
    //             supplierName: 'Daylight Company',
    //             submissionId: '815db372-f5af-40b5-8250-16a106302a6a',
    //             submissionName: null,
    //             expectedScore: 5.0,
    //             actualScore: 0.0,
    //             hasConsensus: false,
    //             allowableRange: null,
    //             deviation: null,
    //           },
    //           {
    //             supplierId: '9e993caa-b78a-4707-aa26-d8f0871a8f93',
    //             supplierName: 'Electronic Company',
    //             submissionId: '0f04edf5-a558-4238-ba1f-ce3091deeb1e',
    //             submissionName: null,
    //             expectedScore: 5.0,
    //             actualScore: 0.0,
    //             hasConsensus: false,
    //             allowableRange: null,
    //             deviation: null,
    //           },
    //         ],
    //       },
    //     ],
    //   },
    //   evaluators: [
    //     {
    //       evaluatorId: '1ef97012-5fd8-4ce9-9b03-52305e37db30',
    //       evaluatorName: 'Chetan Jadhav',
    //     },
    //     {
    //       evaluatorId: 'e301800e-c70d-4852-aba9-be8db2aa6611',
    //       evaluatorName: 'Preethi Preethi',
    //     },
    //     {
    //       evaluatorId: 'a3d836de-6123-4400-a945-97e0c25bfa30',
    //       evaluatorName: 'siddharth Gupta',
    //     },
    //   ],
    //   stages: [
    //     {
    //       id: '8c32d6ac-062a-4ea1-afbf-d25b12c50dcb',
    //       title: 'Stage1',
    //       totalScore: 35.0,
    //       displayOrder: 1,
    //     },
    //     {
    //       id: '4508d136-0b3a-4ba0-9e64-ce20dec0b156',
    //       title: 'Stage3',
    //       totalScore: 25.0,
    //       displayOrder: 3,
    //     },
    //     {
    //       id: '5508d136-0b3a-4ba0-9e64-ce20dec0b156',
    //       title: 'Stage4',
    //       totalScore: 30.0,
    //       displayOrder: 4,
    //     },
    //   ],
    //   suppliers: [
    //     {
    //       supplierId: '93d122f9-dc04-4af1-8b0e-fda4ec034bd9',
    //       supplierName: 'Albert Company',
    //       submissionId: '2c7ad4f3-16df-4a5d-b598-4539457f8e51',
    //       submissionName: null,
    //     },
    //     {
    //       supplierId: 'fca9307f-93d3-42c8-a5f8-aa71c85e31de',
    //       supplierName: 'Bomber Company',
    //       submissionId: '8904f83f-4091-46ea-9399-161ad5ac92c9',
    //       submissionName: null,
    //     },
    //     {
    //       supplierId: '9436f713-a91a-43e9-a332-ae36d4ceed36',
    //       supplierName: 'Cat Company',
    //       submissionId: 'fb8ce4b3-1161-45a0-9c4b-1b6892f4a36b',
    //       submissionName: null,
    //     },
    //     {
    //       supplierId: '96f92115-7227-4560-acf4-c3c80070cef4',
    //       supplierName: 'Daylight Company',
    //       submissionId: '815db372-f5af-40b5-8250-16a106302a6a',
    //       submissionName: null,
    //     },
    //     {
    //       supplierId: '9e993caa-b78a-4707-aa26-d8f0871a8f93',
    //       supplierName: 'Electronic Company',
    //       submissionId: '0f04edf5-a558-4238-ba1f-ce3091deeb1e',
    //       submissionName: null,
    //     },
    //   ],
    // };
    // this.stagesList = this.scoreCardData.stages;
    // this.suppliersList = this.scoreCardData.suppliers;
    // this.evaluatorList = this.scoreCardData.evaluators;

    //this.pageLoader.show();
    this.reviwMeetingService
      .getScorecard(
        this.store.getEvaluationId(),
        this.store.getEvaluationReviewMeetingId(),
        'PL'
      )
      .subscribe({
        next: (data: any) => {
          //this.pageLoader.hide();
          this.scoreCardData = data.data;
          this.stagesList = this.scoreCardData.stages;
          this.suppliersList = this.scoreCardData.suppliers ?? [];
          this.evaluatorList = this.scoreCardData.evaluators ?? [];
          this.setDefaultList();
        },
        error: () => {
          //this.pageLoader.hide();
        },
      });
  }

  refreshGrid() {
    const supplier = this.suppliersList.find(
      s => s.supplierId === this.selectedSupplierId
    );

    this.reviwMeetingService
      .refreshScorecard(
        this.store.getEvaluationId(),
        this.store.getEvaluationReviewMeetingId(),
        'PL',
        this.selectedStageId,
        this.selectedSupplierId,
        supplier?.submissionId
      )
      .subscribe({
        next: (data: any) => {
          this.scoreCardData.selectedStage = data.data.selectedStage;
          this.stagesList = this.scoreCardData.stages;
          this.suppliersList = this.scoreCardData.suppliers ?? [];
          this.evaluatorList = this.scoreCardData.evaluators ?? [];
        },
      });
  }

  getInitials(name: string): string {
    let initials = '';
    const names = name.split(' ');
    names.forEach(n => {
      initials += n.charAt(0).toUpperCase();
    });
    return initials;
  }

  concludeMeeting() {
    const data = {
      evaluationId: this.store.getEvaluationId(),
      reviewMeetingId: this.store.getEvaluationReviewMeetingId(),
    };

    this.reviwMeetingService
      .concludeMeeting(
        this.store.getEvaluationId(),
        this.store.getEvaluationReviewMeetingId(),
        data
      )
      .subscribe({
        next: (data: any) => {
          console.log('Conclude Meeting');
        },
      });
  }
}
