<div class="p-4 review-container">
  <form
    [formGroup]="reviewDecisionForm"
    (ngSubmit)="!this.actionType ? confirmSelection() : modalOpen()">
    <ng-container *ngIf="!showConfirmationMessage">
      <h4 class="fw-bold title">
        {{
          actionType === 'Edit Meeting Details'
            ? 'Edit Review Meeting Details'
            : actionType === 'Change Review Decision'
              ? ' Edit Review Decision'
              : 'Evaluation Review Decision'
        }}
      </h4>
      <p *ngIf="actionType === null">
        Do you want to hold an evaluation review meeting?
      </p>
      <p *ngIf="actionType === 'Change Review Decision'">
        Changing your review decision will impact your current evaluation review
        process. Notifications will be sent to evaluators if updates are made.
      </p>
      <div
        class="d-flex pt-1"
        *ngIf="actionType === null || actionType === 'Change Review Decision'">
        <div
          class="flex-grow-1 option-box p-3 form-outline border-right-none"
          [class.selected]="selectedOption === 'scheduleMeeting'">
          <div class="form-check">
            <input
              formControlName="selectedOption"
              [value]="'scheduleMeeting'"
              class="form-check-input"
              type="radio"
              name="selectedOption"
              id="selectedOption"
              (change)="selectOption('scheduleMeeting')" />
            <label class="form-check-label fw-bold" for="scheduleMeeting">
              Yes - Schedule a Review Meeting
            </label>
          </div>
          <p class="text-align">
            A review meeting will be scheduled. A set of scorecards will be
            enabled to allow adjustments to scores.
          </p>
        </div>
        <div
          class="flex-grow-1 option-box p-3 form-outline"
          [class.selected]="selectedOption === 'averageScore'">
          <div class="form-check">
            <input
              formControlName="selectedOption"
              [value]="'averageScore'"
              class="form-check-input"
              type="radio"
              name="selectedOption"
              id="selectedOption"
              (change)="selectOption('averageScore')" />
            <label class="form-check-label fw-bold" for="averageScore">
              No - Average Score
            </label>
          </div>
          <p class="text-align">
            The average will be applied, and no modifications to the scorecards
            will be permitted.
          </p>
        </div>
      </div>
      <ngb-alert
        [dismissible]="false"
        [type]="'info'"
        *ngIf="
          selectedOption === 'averageScore' &&
          (actionType === null || actionType === 'Change Review Decision')
        "
        class="mt-3">
        <app-icon
          [class]="'no-selection mb-1'"
          [type]="'alert-triangle'"
          [width]="22"
          [height]="22"></app-icon>
        You selected to finalize the evaluation using average scores. This
        action will proceed without any meetings.
      </ngb-alert>

      <!-- "Yes" is selected -->
      <div *ngIf="selectedOption === 'scheduleMeeting'">
        <h5 class="mt-4 stage-include-title">
          Stages Included in the Review Meeting
        </h5>
        <div ngbDropdown class="d-inline-block dropdown">
          <button
            class="btn dropdown-toggle w-100 text-start"
            type="button"
            ngbDropdownToggle>
            <div class="stages-header">Stages to Include</div>
            <div>All Stages</div>
            <div class="chevron-down chevron" (click)="toggleDropdown()">
              <app-icon
                [type]="showStagesDropdown ? 'chevron-up' : 'chevron-down'"
                [width]="20"
                [height]="20">
              </app-icon>
            </div>
          </button>
          <div
            [ngStyle]="{ display: showStagesDropdown ? 'block' : 'none' }"
            class="dropdown-menu show w-100">
            <div *ngFor="let stage of stages; let i = index">
              <input
                formControlName="stage"
                type="checkbox"
                [id]="stage.id"
                [value]="stage.selected"
                [(ngModel)]="stage.selected" />
              <label [for]="stage.id">{{ i + 1 }}. {{ stage.title }}</label>
            </div>
          </div>
        </div>

        <h5
          class="mt-4 content-below stage-include-title"
          [ngClass]="{ 'mt-3': showStagesDropdown }">
          Score Card Permissions
        </h5>
        <div class="d-flex">
          <div
            class="option-box form-outline border-right-none p-3"
            [class.selected]="selectedPermission === 'lead'">
            <div class="form-check">
              <input
                formControlName="selectedPermission"
                [value]="'lead'"
                class="form-check-input"
                type="radio"
                name="selectedPermission"
                id="selectedPermission"
                (change)="selectPermission('lead')" />
              <label class="form-check-label fw-bold" for="lead"
                >Procurement Lead Only</label
              >
            </div>
            <p class="text-align">
              Only the Procurement Lead has the permission to update the scores
              and provide comments.
            </p>
          </div>

          <div
            class="option-box form-outline p-3"
            [class.selected]="selectedPermission === 'unlocked'">
            <div class="form-check">
              <input
                formControlName="selectedPermission"
                [value]="'unlocked'"
                class="form-check-input"
                type="radio"
                name="selectedPermission"
                id="selectedPermission"
                (change)="selectPermission('unlocked')" />
              <label class="form-check-label fw-bold" for="unlocked"
                >Unlocked Evaluators</label
              >
            </div>
            <p class="text-align">
              All evaluators have permission to update scores and add comments.
            </p>
          </div>
        </div>

        <h5 class="mt-4 stage-include-title">Deviation</h5>
        <div class="mb-2">
          Deviation required to identify lack of consensus.
        </div>
        <div class="d-flex align-items-center">
          <input
            formControlName="deviation"
            type="number"
            class="form-control w-25 me-2 p-3"
            placeholder="Value (%)"
            [max]="100" />
          <small
            >Enter the percentage deviation allowed between scores<br />(e.g.,
            10% will flag scores differing by more than 10%)</small
          >
        </div>

        <!-- <h5 class="mt-4 stage-include-title">Evaluator Management</h5>
    <div>
      <label class="me-4">Show Evaluator Names?</label>
      <input
        type="radio"
        name="evaluatorNames"
        id="yes"
        [(ngModel)]="showNames"
        [value]="true"
        class="form-check-input" />
      <label for="yes" class="me-4">Yes</label>
      <input
        type="radio"
        name="evaluatorNames"
        id="no"
        [(ngModel)]="showNames"
        [value]="false"
        class="form-check-input" />
      <label for="no">No</label>
    </div> -->

        <h5 class="mt-4 stage-include-title">Review Meeting Date & Time</h5>
        <div class="d-flex align-items-center">
          <input
            formControlName="reviewDate"
            type="date"
            class="form-control w-25 me-2" />
          <input
            formControlName="reviewStartTime"
            type="time"
            class="form-control w-25 me-2" />
          <input
            formControlName="reviewEndTime"
            type="time"
            class="form-control w-25" />
        </div>
      </div>
      <div class="mt-4">
        <button
          id="confirmBtn"
          type="submit"
          class="btn btn-primary me-2 confirm-btn"
          [disabled]="
            stages.length === 0 ||
            reviewDecisionForm.invalid ||
            (statusData.reviewMeetingStatus !== 'Not Exists' &&
              isFormNotChanged)
          ">
          Confirm Selection
        </button>
        <button
          class="btn btn-outline-secondary cancel-btn"
          (click)="closeModal()">
          Cancel
        </button>
      </div>
    </ng-container>
  </form>
</div>
<app-modal
  *ngIf="showError"
  (closeModalEvent)="closeErrorModal()"
  [headerClass]="'custom-header'"
  [modalSize]="'xl'">
  <!-- Confirmation Message -->

  <div class="text-center">
    <img
      [src]="path.getAssetUrl('/assets/svg/alert-circle.png')"
      alt="alert circle icon" />
    <h5 class="title">Schedule Review Meeting - Action Not Allowed</h5>
    <p>
      You cannot schedule a review meeting before all selected evaluation stages
      have been completed.<br />The review meeting can only be scheduled after
      the end date of the final selected evaluation stage. <br />Please ensure
      the meeting is scheduled after all selected evaluations are complete.
    </p>
    <button class="btn btn-primary cancel" (click)="closeErrorModal()">
      Close
    </button>
  </div></app-modal
>
<app-confirm-dialog
  *ngIf="showConfirmDialog"
  [message]="confirmationMessage"
  [dialogTitle]="confirmationTitle"
  cancelButtonText="Cancel"
  [confirmButtonText]="'Proceed'"
  [iconType]="'alert-circle'"
  (dialogConfirmed)="confirmSelection()"
  (dialogCancel)="modalCancel()"
  [type]="'error'">
</app-confirm-dialog>
