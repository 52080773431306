import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { StatusComponent } from './components/status/status.component';
import { IconComponent } from './components/icon/icon.component';
import { PageLoaderComponent } from './components/page-loader/page-loader.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { TextEditorComponent } from './components/text-editor/text-editor.component';
import { FormsModule } from '@angular/forms';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { LoaderComponent } from './components/loader/loader.component';
import { LandingPageErrorComponent } from './components/landing-page-error/landing-page-error.component';

@NgModule({
  declarations: [
    SidebarComponent,
    StatusComponent,
    IconComponent,
    PageLoaderComponent,
    ConfirmDialogComponent,
    TextEditorComponent,
    LoaderComponent,
    LandingPageErrorComponent,
  ],
  exports: [
    SidebarComponent,
    StatusComponent,
    IconComponent,
    PageLoaderComponent,
    LoaderComponent,
    ConfirmDialogComponent,
    LandingPageErrorComponent,
    RouterModule,
    TextEditorComponent,
  ],
  imports: [CommonModule, RouterModule, FormsModule, CKEditorModule],
})
export class CoreModule {}
