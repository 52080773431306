import { Component, Output, EventEmitter, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AssetsPathChangeService } from 'src/app/core/services/core/assets-path-change.service';
import { ReviewMeetingService } from 'src/app/core/services/review-meeting/review-meeting.service';
import { StoreService } from 'src/app/core/services/store.service';

@Component({
  selector: 'app-review-decision',
  templateUrl: './review-decision.component.html',
  styleUrls: ['./review-decision.component.scss'],
})
export class ReviewDecisionComponent implements OnInit {
  @Input() statusData: any = null;
  @Input() actionType: any = null;
  @Input() reviewMeeingId: any = null;
  @Output() closeModalEvent = new EventEmitter<void>();
  selectedOption: string | any = null;
  scoreCardPermission: string | null = null;
  selectedPermission: string | null = null;
  showStagesDropdown = false;
  showNames = false;
  showReviewModal = false;
  showConfirmationMessage = false;
  showError = false;
  deviation = 0;
  reviewDate: Date | string = '';
  reviewStartTime: Date | string = '';
  reviewEndTime: Date | string = '';
  reviewDecisionForm: FormGroup = new FormGroup({
    selectedOption: new FormControl<any>(null, [Validators.required]),
    stage: new FormControl<any>([]),
    selectedPermission: new FormControl<any>(null, [Validators.required]),
    deviation: new FormControl<any>(0),
    reviewDate: new FormControl<any>('', [Validators.required]),
    reviewStartTime: new FormControl<any>('', [Validators.required]),
    reviewEndTime: new FormControl<any>('', [Validators.required]),
    evaluationReviewMeetingId: new FormControl<any>(0),
    isMeetingConcluded: new FormControl<any>(0),
    isScoreCardConfirmed: new FormControl<any>(false),
    meetingConcludedOn: new FormControl<any>(null),
    reviewMeetingStatus: new FormControl<any>(false),
    scoreCardConfirmedOn: new FormControl<any>(null),
  });
  showConfirmDialog = false;
  confirmationTitle = '';
  confirmationMessage = '';
  evaluationId: any = null;

  constructor(
    private reviwMeetingService: ReviewMeetingService,
    public path: AssetsPathChangeService,
    private store: StoreService
  ) {}

  stages: any = [];
  ngOnInit(): void {
    this.evaluationId = this.store.getEvaluationId();
    this.getAllStages();
  }
  selectOption(option: string) {
    this.selectedOption = option;
    this.validateForm();
  }
  validateForm() {
    if (this.selectedOption == 'scheduleMeeting') {
      this.reviewDecisionForm
        .get('selectedPermission')
        ?.setValidators([Validators.required]);
      this.reviewDecisionForm
        .get('selectedPermission')
        ?.updateValueAndValidity();
      this.reviewDecisionForm
        .get('reviewDate')
        ?.setValidators([Validators.required]);
      this.reviewDecisionForm.get('reviewDate')?.updateValueAndValidity();
      this.reviewDecisionForm
        .get('reviewStartTime')
        ?.setValidators([Validators.required]);
      this.reviewDecisionForm.get('reviewStartTime')?.updateValueAndValidity();
      this.reviewDecisionForm
        .get('reviewEndTime')
        ?.setValidators([Validators.required]);
      this.reviewDecisionForm.get('reviewEndTime')?.updateValueAndValidity();
    } else {
      this.reviewDecisionForm.get('selectedPermission')?.clearValidators();
      this.reviewDecisionForm
        .get('selectedPermission')
        ?.updateValueAndValidity();
      this.reviewDecisionForm.get('reviewDate')?.clearValidators();
      this.reviewDecisionForm.get('reviewDate')?.updateValueAndValidity();
      this.reviewDecisionForm.get('reviewStartTime')?.clearValidators();
      this.reviewDecisionForm.get('reviewStartTime')?.updateValueAndValidity();
      this.reviewDecisionForm.get('reviewEndTime')?.clearValidators();
      this.reviewDecisionForm.get('reviewEndTime')?.updateValueAndValidity();
    }
  }

  selectPermission(permission: string) {
    this.selectedPermission = permission;
  }

  toggleDropdown() {
    this.showStagesDropdown = !this.showStagesDropdown;
  }

  closeModal() {
    this.showReviewModal = false;
    this.closeModalEvent.emit();
    this.selectedOption = null;
    this.scoreCardPermission = null;
    this.selectedPermission = null;
    this.showStagesDropdown = false;
    this.deviation = 0;
    this.reviewDate = '';
    this.reviewStartTime = '';
    this.reviewEndTime = '';
    this.reviewDecisionForm.reset();
  }
  closeErrorModal() {
    this.showError = false;
  }
  evaluationTitle = 'Evaluation Review Decision';
  modalOpen() {
    const selectedOption = this.reviewDecisionForm.get('selectedOption')?.value;
    const oldSelectedValue = JSON.parse(
      this.reviewDecisionFormDataJson
    ).selectedOption;

    if (
      oldSelectedValue == 'scheduleMeeting' &&
      selectedOption == 'averageScore'
    ) {
      this.confirmationTitle = 'Save Changes?';
      this.confirmationMessage =
        'Switching from a meeting to average score will cancel the scheduled meeting and notify all evaluators of this change. Are you sure you want to proceed?';
    } else if (
      oldSelectedValue == 'averageScore' &&
      selectedOption == 'scheduleMeeting'
    ) {
      this.confirmationTitle = 'Save Changes?';
      this.confirmationMessage =
        ' Switching from average score to a review meeting will trigger an expanded version of the evaluation review decision. Are you sure you want to proceed?';
    } else if (
      oldSelectedValue == 'scheduleMeeting' &&
      selectedOption == 'scheduleMeeting'
    ) {
      this.confirmationMessage =
        'You are about to update the evaluation review meeting details. Evaluators will be notified of these changes. Do you wish to proceed?';
    } else if (
      oldSelectedValue == 'averageScore' &&
      selectedOption == 'averageScore'
    ) {
      this.confirmationMessage =
        'Changing your review decision will impact your current evaluation review process. Notifications will be sent to evaluators if updates are made';
    }

    this.showConfirmDialog = true;
  }
  modalCancel() {
    this.showConfirmDialog = false;
  }
  confirmSelection() {
    if (!this.actionType) this.postEvaluationreviewDetails();
    else this.putEvaluationreviewDetails();
  }

  getEvaluationreviewDetails() {
    this.reviwMeetingService
      .getEvaluationreviewDetails(this.evaluationId)
      .subscribe({
        next: (data: any) => {
          if (!data) return;
          this.selectedOption = data.meetingType
            ? data.meetingType == 1
              ? 'scheduleMeeting'
              : 'averageScore'
            : null;
          this.stages.forEach((stage: any) => {
            stage.selected = data.stages
              ? data.stages.map((item: any) => item.stageId).includes(stage.id)
              : false;
          });
          this.selectedPermission = data.meetingPermissionId
            ? data.meetingPermissionId == 1
              ? 'lead'
              : 'manager'
            : null;
          if (data.meetingStartDateTimeUtc && data.meetingEndDateTimeUtc) {
            const startDate = new Date(data.meetingStartDateTimeUtc);
            const endDate = new Date(data.meetingEndDateTimeUtc);
            const dateOnly = startDate.toISOString().split('T')[0];
            this.reviewDate = new Date(dateOnly).toISOString().split('T')[0];
            const startTimeOnly = startDate.toLocaleTimeString('en-GB');
            const endTimeOnly = endDate.toLocaleTimeString('en-GB');
            this.reviewStartTime = startTimeOnly;
            this.reviewEndTime = endTimeOnly;
          } else {
            this.reviewDate = '';
            this.reviewStartTime = '';
            this.reviewEndTime = '';
          }
          this.reviewDecisionForm.patchValue(
            {
              evaluationReviewMeetingId: data.evaluationReviewMeetingId,
              selectedOption: data.meetingType
                ? data.meetingType == 1
                  ? 'scheduleMeeting'
                  : 'averageScore'
                : null,
              stage: this.stages,
              selectedPermission: data.meetingPermissionId
                ? data.meetingPermissionId == 1
                  ? 'lead'
                  : 'manager'
                : null,
              deviation: data.deviation,
              reviewDate: this.reviewDate,
              reviewStartTime: this.reviewStartTime,
              reviewEndTime: this.reviewEndTime,
              isMeetingConcluded: this.statusData.isMeetingConcluded ?? false,
              isScoreCardConfirmed:
                this.statusData.isScoreCardConfirmed ?? false,
              meetingConcludedOn: this.statusData.meetingConcludedOn ?? null,
              reviewMeetingStatus: data.reviewMeetingStatus ?? '',
              scoreCardConfirmedOn:
                this.statusData.scoreCardConfirmedOn ?? null,
            },
            { emitEvent: false }
          );

          if (this.statusData.reviewMeetingStatus !== 'Not Exists') {
            this.reviewDecisionFormDataJson = JSON.stringify(
              this.reviewDecisionForm.value
            );
            this.reviewDecisionForm.valueChanges.subscribe((data: any) => {
              this.checkConfirmButton();
            });
          }
          this.validateForm();
        },
        error: e => {
          console.log(e);
        },
      });
  }
  isFormNotChanged: any = true;
  reviewDecisionFormDataJson: any = null;
  getAllStages() {
    this.reviwMeetingService.getAllStages(this.evaluationId).subscribe({
      next: (data: any) => {
        this.stages = data.data.stages.map((stage: any) => {
          return { ...stage, selected: false };
        });
        this.getEvaluationreviewDetails();
      },
      error: e => {
        console.log(e);
      },
    });
  }
  checkConfirmButton() {
    const reviewDecisionFormDataJson = JSON.stringify(
      this.reviewDecisionForm.value
    );
    if (reviewDecisionFormDataJson == this.reviewDecisionFormDataJson)
      this.isFormNotChanged = true;
    else this.isFormNotChanged = false;
  }
  postEvaluationreviewDetails() {
    const data =
      this.selectedOption != 'scheduleMeeting'
        ? {
            evaluationReviewDecisionRequest: {
              meetingType: this.selectedOption == 'scheduleMeeting' ? 1 : 2,
              evaluationId: this.evaluationId,
              isMeetingConcluded:
                this.reviewDecisionForm.get('isMeetingConcluded')?.value,
              isScoreCardConfirmed: this.reviewDecisionForm.get(
                'isScoreCardConfirmed'
              )?.value,
              meetingConcludedOn:
                this.reviewDecisionForm.get('meetingConcludedOn')?.value,
              reviewMeetingStatus: 1,
              scoreCardConfirmedOn: this.reviewDecisionForm.get(
                'scoreCardConfirmedOn'
              )?.value,
            },
          }
        : {
            evaluationReviewDecisionRequest: {
              meetingPermissionId: this.selectedPermission == 'lead' ? 1 : 2,
              meetingType: this.selectedOption == 'scheduleMeeting' ? 1 : 2,
              evaluationId: this.evaluationId,
              stages:
                this.stages
                  .filter((stage: any) => stage.selected)
                  .map((stage: any) => stage.id).length == 0
                  ? this.stages.map((stage: any) => {
                      return {
                        stageId: stage.id,
                        stageEndDate: stage.endDateUtc,
                      };
                    })
                  : this.stages
                      .filter((stage: any) => stage.selected)
                      .map((stage: any) => {
                        return {
                          stageId: stage.id,
                          stageEndDate: stage.endDateUtc,
                        };
                      }),
              deviation: this.reviewDecisionForm.get('deviation')?.value,
              meetingStartDateTimeUtc: new Date(
                `${this.reviewDecisionForm.get('reviewDate')?.value}T${
                  this.reviewDecisionForm.get('reviewStartTime')?.value
                }`
              ),
              meetingEndDateTimeUtc: new Date(
                `${this.reviewDecisionForm.get('reviewDate')?.value}T${
                  this.reviewDecisionForm.get('reviewEndTime')?.value
                }`
              ),
              isScorecardGenerated: this.statusData.isScoreCardGenerated,
              scorecardGeneratedOn: this.statusData.scoreCardGeneratedOn,
              isDeleted: false,
              createdBy: 'bbabcf64-5717-4562-b3fc-2c963f66afaa',

              isMeetingConcluded:
                this.reviewDecisionForm.get('isMeetingConcluded')?.value,
              isScoreCardConfirmed: this.reviewDecisionForm.get(
                'isScoreCardConfirmed'
              )?.value,
              meetingConcludedOn:
                this.reviewDecisionForm.get('meetingConcludedOn')?.value,
              reviewMeetingStatus: 1,
              scoreCardConfirmedOn: this.reviewDecisionForm.get(
                'scoreCardConfirmedOn'
              )?.value,
            },
          };
    this.reviwMeetingService
      .postEvaluationreviewDetails(this.evaluationId, data)
      .subscribe({
        next: (_data: any) => {
          this.closeModal();
        },
        error: e => {
          if (e.status == 405) this.showError = true;
        },
      });
  }
  putEvaluationreviewDetails() {
    const data =
      this.selectedOption != 'scheduleMeeting'
        ? {
            evaluationReviewDecisionRequest: {
              evaluationReviewMeetingId: this.reviewDecisionForm.get(
                'evaluationReviewMeetingId'
              )?.value,
              evaluationId: this.evaluationId,
              meetingType: this.selectedOption == 'scheduleMeeting' ? 1 : 2,
              reviewMeetingStatus: this.reviewDecisionForm.get(
                'reviewMeetingStatus'
              )?.value,
              isMeetingConcluded:
                this.reviewDecisionForm.get('isMeetingConcluded')?.value,
              isScoreCardConfirmed: this.reviewDecisionForm.get(
                'isScoreCardConfirmed'
              )?.value,
              meetingConcludedOn:
                this.reviewDecisionForm.get('meetingConcludedOn')?.value,
              scoreCardConfirmedOn: this.reviewDecisionForm.get(
                'scoreCardConfirmedOn'
              )?.value,
            },
          }
        : {
            evaluationReviewDecisionRequest: {
              evaluationReviewMeetingId: this.reviewDecisionForm.get(
                'evaluationReviewMeetingId'
              )?.value,
              evaluationId: this.evaluationId,
              meetingPermissionId: this.selectedPermission == 'lead' ? 1 : 2,
              meetingType: this.selectedOption == 'scheduleMeeting' ? 1 : 2,
              stages:
                this.stages
                  .filter((stage: any) => stage.selected)
                  .map((stage: any) => stage.id).length == 0
                  ? this.stages.map((stage: any) => {
                      return {
                        stageId: stage.id,
                        stageEndDate: stage.endDateUtc,
                      };
                    })
                  : this.stages
                      .filter((stage: any) => stage.selected)
                      .map((stage: any) => {
                        return {
                          stageId: stage.id,
                          stageEndDate: stage.endDateUtc,
                        };
                      }),
              deviation: this.reviewDecisionForm.get('deviation')?.value,
              meetingStartDateTimeUtc: new Date(
                `${this.reviewDecisionForm.get('reviewDate')?.value}T${
                  this.reviewDecisionForm.get('reviewStartTime')?.value
                }`
              ),
              meetingEndDateTimeUtc: new Date(
                `${this.reviewDecisionForm.get('reviewDate')?.value}T${
                  this.reviewDecisionForm.get('reviewEndTime')?.value
                }`
              ),
              isScorecardGenerated: this.statusData.isScoreCardGenerated,
              scorecardGeneratedOn: this.statusData.scoreCardGeneratedOn,
              isDeleted: false,
              createdBy: 'bbabcf64-5717-4562-b3fc-2c963f66afaa',
              isMeetingConcluded:
                this.reviewDecisionForm.get('isMeetingConcluded')?.value,
              isScoreCardConfirmed: this.reviewDecisionForm.get(
                'isScoreCardConfirmed'
              )?.value,
              meetingConcludedOn:
                this.reviewDecisionForm.get('meetingConcludedOn')?.value,
              reviewMeetingStatus: this.reviewDecisionForm.get(
                'reviewMeetingStatus'
              )?.value,

              scoreCardConfirmedOn: this.reviewDecisionForm.get(
                'scoreCardConfirmedOn'
              )?.value,
            },
          };
    this.reviwMeetingService
      .putEvaluationreviewDetails(this.evaluationId, this.reviewMeeingId, data)
      .subscribe({
        next: (_data: any) => {
          this.closeModal();
        },
        error: e => {
          if (e.status == 405) this.showError = true;
        },
      });
  }
}
