import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent {
  @Input() dialogSubTitle = '';
  @Input() message = 'Are you sure you want ot proceed?';
  @Input() confirmButtonText = 'Confirm';
  @Input() cancelButtonText = 'Cancel';
  @Input() iconType = 'default'; //Icon can be changed dynamically
  @Input() type = '';
  @Output() confirmed = new EventEmitter<boolean>();
  @Output() buttonClicked = new EventEmitter<void>();

  //Cancel Button Event
  onCancel() {
    this.buttonClicked.emit();
  }
  //Confirm Button Event
  onConfirm(): void {
    this.confirmed.emit(true);
  }
}
