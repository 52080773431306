import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'build-evaluation',
    loadChildren: () =>
      import('./modules/pages/build-evaluation/build-evaluation.module').then(
        m => m.BuildEvaluationModule
      ),
  },
  {
    path: 'evaluation-team',
    loadChildren: () =>
      import('./modules/pages/evaluation-team/evaluation-team.module').then(
        m => m.EvaluationTeamModule
      ),
  },
  {
    path: 'review-meetings',
    loadChildren: () =>
      import('./modules/pages/review-meetings/review-meetings.module').then(
        m => m.ReviewMeetingsModule
      ),
  },
  {
    path: 'supplier-status',
    loadChildren: () =>
      import('./modules/pages/supplier-status/supplier-status.module').then(
        m => m.SupplierStatusModule
      ),
  },
  { path: '', redirectTo: 'build-evaluation', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
