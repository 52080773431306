<div
  class="modal fade"
  id="confirmationModal"
  tabindex="-1"
  aria-labelledby="confirmationModalLabel"
  aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header border-0">
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"></button>
      </div>
      <div class="modal-body text-center">
        <div class="icon-container">
          <app-icon
            [class]="type"
            [type]="iconType"
            [width]="52"
            [height]="52"></app-icon>
        </div>
        <h5 class="mt-3 fw-bold">{{ confirmButtonText }}</h5>
        <h5 class="mt-3 fw-bold" *ngIf="dialogSubTitle">
          {{ dialogSubTitle }}
        </h5>
        <p>{{ message }}</p>
      </div>
      <div class="modal-footer justify-content-center border-0">
        <button
          type="button"
          class="btn btn-danger min-width"
          (click)="onConfirm()"
          data-bs-dismiss="modal">
          Confirm
        </button>
        <button
          type="button"
          class="btn btn-secondary min-width"
          (click)="onCancel()"
          data-bs-dismiss="modal">
          Cancel
        </button>
      </div>
    </div>
  </div>
</div>
