import { Component, Input, ViewEncapsulation } from '@angular/core';

import { ChangeDetectorRef } from '@angular/core';
import { ChangeEvent } from '@ckeditor/ckeditor5-angular';

import {
  ClassicEditor,
  Heading,
  AccessibilityHelp,
  Alignment,
  Autoformat,
  Autosave,
  Bold,
  CloudServices,
  Essentials,
  GeneralHtmlSupport,
  ImageInsertViaUrl,
  ImageUpload,
  Italic,
  List,
  Paragraph,
  SelectAll,
  Strikethrough,
  TextTransformation,
  Underline,
  Undo,
  type EditorConfig,
} from 'ckeditor5';

@Component({
  selector: 'app-text-editor',
  templateUrl: './text-editor.component.html',
  styleUrls: ['./text-editor.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TextEditorComponent {
  @Input() confirmReason = { text: '' };
  constructor(private changeDetector: ChangeDetectorRef) {}

  public isLayoutReady = false;
  public Editor = ClassicEditor;
  public config: EditorConfig = {}; // CKEditor needs the DOM tree before calculating the configuration.
  public ngAfterViewInit(): void {
    this.config = {
      toolbar: {
        items: [
          'undo',
          'redo',
          'heading',
          'alignment',
          'bold',
          'italic',
          'underline',
          'strikethrough',
          'bulletedList',
          'numberedList',
        ],
        shouldNotGroupWhenFull: false,
      },
      plugins: [
        AccessibilityHelp,
        Alignment,
        Heading,
        Autoformat,
        Autosave,
        Bold,
        CloudServices,
        Essentials,
        GeneralHtmlSupport,
        ImageInsertViaUrl,
        ImageUpload,
        Italic,
        List,
        Paragraph,
        SelectAll,
        Strikethrough,
        TextTransformation,
        Underline,
        Undo,
      ],
      heading: {
        options: [
          {
            model: 'paragraph',
            title: 'Normal text',
            class: 'ck-heading_paragraph',
          },
          {
            model: 'heading1',
            view: 'h1',
            title: 'Heading 1',
            class: 'ck-heading_heading1',
          },
          {
            model: 'heading2',
            view: 'h2',
            title: 'Heading 2',
            class: 'ck-heading_heading2',
          },
          {
            model: 'heading3',
            view: 'h3',
            title: 'Heading 3',
            class: 'ck-heading_heading3',
          },
          {
            model: 'heading4',
            view: 'h4',
            title: 'Heading 4',
            class: 'ck-heading_heading4',
          },
          {
            model: 'heading5',
            view: 'h5',
            title: 'Heading 5',
            class: 'ck-heading_heading5',
          },
          {
            model: 'heading6',
            view: 'h6',
            title: 'Heading 6',
            class: 'ck-heading_heading6',
          },
        ],
      },
      htmlSupport: {
        allow: [
          {
            name: /^.*$/,
            styles: true,
            attributes: true,
            classes: true,
          },
        ],
      },
      initialData: '',
      placeholder: 'Please explain why this evaluation item is being removed.',
    };

    this.isLayoutReady = true;
    this.changeDetector.detectChanges();
  }

  onChange({ editor }: ChangeEvent) {
    this.config.initialData = editor.getData();
    console.log(this.config.initialData, this.confirmReason);
  }
}
