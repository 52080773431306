import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class StoreService {
  private evaluationId$: BehaviorSubject<any> = new BehaviorSubject('');
  private evaluationDetails$: BehaviorSubject<any> = new BehaviorSubject({});
  private buildFlowActions$ = new Subject<any>();
  private proposalResponse$ = new BehaviorSubject<any>([]);

  updateEvaluationId(id: string) {
    this.evaluationId$.next(id);
  }

  getEvaluationId() {
    return this.evaluationId$?.getValue();
  }

  get getEvaluationIdAsObservable() {
    return this.evaluationId$.asObservable();
  }

  getEvaluationScoringMethodId() {
    return this.evaluationDetails$?.getValue()?.scoringMethodId;
  }

  updateEvaluationDetails(data: any) {
    this.evaluationDetails$.next(data);
  }
  get getEvaluationDetails() {
    return this.evaluationDetails$.asObservable();
  }

  setBuildFlowActionsData(data: any) {
    this.buildFlowActions$.next(data);
  }
  get getBuildFlowActionsData() {
    return this.buildFlowActions$.asObservable();
  }

  setProposalResponse(data: any) {
    this.proposalResponse$.next(data);
  }

  getProposalResponse() {
    return this.proposalResponse$?.getValue();
  }
}
