<div *ngIf="evaluationreviewmeetingStatus === 'Not Exists'; else elseTemplate">
  <h1 class="pb-1">Plan a Review Meeting or Choose Average Score Method</h1>

  <div class="review-container">
    <p class="review-desc mb-4 pb-1">
      Choose how to proceed with the evaluation scoring method:
    </p>
    <ul class="mt-2">
      <li>
        Plan a review meeting to discuss and adjust scores after evaluations are
        completed, or
      </li>
      <li>Use the average scores to finalize results without a meeting.</li>
    </ul>
  </div>
  <div
    [class]="
      infoMessage.type === 'error'
        ? ' warning-alert-continer'
        : '  info-alert-continer'
    "
    *ngIf="infoMessage.show">
    <span class="col-12 ps-2">
      <small>
        <app-icon
          [class]="infoMessage.type === 'error' ? 'warning pe-2' : ' info pe-2'"
          [type]="'alert-circle'"
          [width]="20"
          [height]="20"></app-icon>
        {{ infoMessage.message }}
      </small>
    </span>
  </div>
</div>

<ng-template #elseTemplate>
  <div>
    <h1 class="pb-1">{{ screenTitle }}</h1>

    <div class="review-container">
      <p class="review-desc mb-4 pb-1">
        {{ screenDescription }}
      </p>
    </div>
    <div
      [class]="
        infoMessage.type === 'error'
          ? 'warning-alert-continer'
          : 'info-alert-continer'
      "
      *ngIf="infoMessage.show">
      <span class="col-12 ps-2">
        <small>
          <app-icon
            [class]="
              infoMessage.type === 'error' ? 'warning pe-2' : 'info pe-2'
            "
            [type]="
              infoMessage.type === 'error'
                ? 'alert-triangle'
                : '/assets/svg/info-alert.svg'
            "
            [width]="20"
            [height]="20"></app-icon>
          {{ infoMessage.message }}
        </small>
      </span>
    </div>
  </div>
</ng-template>

<div class="card text-center py-4">
  <div class="card-body">
    <div class="col-12 d-flex flex-column">
      <div class="action_btn">
        <div
          ngbDropdown
          class="d-inline-block"
          placement="bottom-end"
          *ngIf="evaluationreviewmeetingStatus !== 'Not Exists'">
          <button
            class="btn btn-secondary dropdown-toggle min-width custom-icon actions-evaluation"
            type="button"
            ngbDropdownToggle
            aria-expanded="false"
            id="evaluation-team-dropdown">
            Actions
            <app-icon
              class="icon"
              [type]="'more-horizontal'"
              [width]="18"
              [height]="18"
              [strokeWidth]="3"></app-icon>
          </button>
          <div ngbDropdownMenu aria-labelledby="evaluation-team-dropdown">
            <button
              *ngIf="statusData.meetingType === 1"
              ngbDropdownItem
              class="evaluation-action"
              type="button"
              (click)="onClickActions('Edit Meeting Details')">
              Edit Meeting Details
            </button>
            <button
              ngbDropdownItem
              class="evaluation-action"
              type="button"
              (click)="onClickActions('Change Review Decision')">
              Change Review Decision
            </button>
            <button
              ngbDropdownItem
              class="evaluation-action"
              type="button"
              (click)="onClickActions('Cancel Meeting')">
              Cancel Meeting
            </button>
            <button
              ngbDropdownItem
              class="evaluation-action"
              type="button"
              [disabled]="true"
              (click)="onClickActions('Download Evaluation Details')">
              Download Evaluation Details
            </button>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="img-wrapper mb-3">
        <span>
          <img [src]="path.getAssetUrl(imageUrl)" />
        </span>
      </div>
      <h2>{{ headerText }}</h2>
      <p class="mb-2">
        {{ paragraphText }}
      </p>
      <p
        class="mb-2"
        *ngIf="
          evaluationreviewmeetingStatus === 'Meeting Start Soon' ||
          evaluationreviewmeetingStatus === 'Meeting In Progress'
        ">
        <span class="indicator">
          <span class="circle"></span>
          {{ paragraph2Text }}
        </span>
      </p>
      <button
        type="button"
        class="btn btn-primary mt-2"
        [disabled]="buttonStatus"
        (click)="methodCall()">
        {{ buttonText }}
      </button>
    </div>
  </div>
</div>

<app-modal
  *ngIf="showReviewModal"
  (closeModalEvent)="closeModal()"
  [headerClass]="'custom-header'"
  [modalSize]="'xl'">
  <app-review-decision
    [actionType]="actionType"
    [statusData]="statusData"
    [reviewMeeingId]="evaluationreviewmeetingId"
    [actionType]="actionType"
    *ngIf="showReviewModal"
    (closeModalEvent)="closeModal()"></app-review-decision
></app-modal>
<app-global-modal
  *ngIf="showReviewGrid"
  (closeModalEvent)="closeGrid()"
  (meetingActions)="meetingActions()"
  [additionalInfo]="meetingActionStatus">
  <app-review-grid
    *ngIf="showReviewGrid"
    (closeModalEvent)="closeGrid()"></app-review-grid
></app-global-modal>

<app-confirm-dialog
  *ngIf="showConfirmDialog"
  [message]="
    'Are you sure you want to cancel the review meeting? The action will notify all evaluators that the meeting has been canceled.'
  "
  [dialogTitle]="'Cancel Review Meeting?'"
  cancelButtonText="No, Go Back"
  [confirmButtonText]="'Yes, Cancel Meeting'"
  [iconType]="'alert-circle'"
  (dialogConfirmed)="cancelMeeting()"
  (dialogCancel)="modalCancel()"
  [type]="'error'">
</app-confirm-dialog>
<app-confirm-dialog
  *ngIf="showConfirmDialogForReopen"
  [message]="
    'You are about to reopen the Evaluation Review. This action will revert the Evaluation Review to its pre-finalization state, allowing further updates and modification by the procurement lead and evaluators, if applicatbel. All evaluators and stakeholders will be notified of the action.'
  "
  [dialogTitle]="'Reopen Evaluation Review'"
  cancelButtonText="Cancel"
  [confirmButtonText]="'Confirm Reopen'"
  [iconType]="'alert-circle'"
  (dialogConfirmed)="reopen()"
  (dialogCancel)="cancelReopen()"
  [type]="'error'">
</app-confirm-dialog>
