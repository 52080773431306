import { Component, OnDestroy, OnInit } from '@angular/core';
import { EvaluationService } from 'src/app/core/services/evaluation.service';
import { StoreService } from '../../services/store.service';
import { takeWhile } from 'rxjs';

@Component({
  selector: 'app-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.scss'],
})
export class StatusComponent implements OnInit, OnDestroy {
  currentStatus = 'active';
  evaluationStatusLabel: any = 'Evaluating';
  _subscribed = true;

  constructor(
    private evaluation: EvaluationService,
    private store: StoreService
  ) {}

  ngOnInit(): void {
    this.subscribeStore();
  }

  subscribeStore() {
    this.store.getEvaluationIdAsObservable
      .pipe(takeWhile(() => this._subscribed))
      .subscribe(id => {
        if (id) {
          //this.getEvaluationStatus(id);
        }
      });
  }

  getEvaluationStatus(id: any) {
    this.evaluation.getEvaluationStatusById(id).subscribe({
      next: (data: any) => {
        this.evaluationStatusLabel = data.evaluationStatus;
      },
      error: e => {
        console.log('Error while fetching Evaluation Status');
      },
    });
  }

  ngOnDestroy(): void {
    this._subscribed = false;
  }
}
