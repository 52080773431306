import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { StatusComponent } from './components/status/status.component';
import { IconComponent } from './components/icon/icon.component';
import { PageLoaderComponent } from './components/page-loader/page-loader.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';

@NgModule({
  declarations: [
    SidebarComponent,
    StatusComponent,
    IconComponent,
    PageLoaderComponent,
    ConfirmDialogComponent,
  ],
  exports: [
    SidebarComponent,
    StatusComponent,
    IconComponent,
    PageLoaderComponent,
    ConfirmDialogComponent,
    RouterModule,
  ],
  imports: [CommonModule, RouterModule],
})
export class CoreModule {}
