import { environment } from '../../../environments/environment';
const baseUrl = environment.BASE_URL;
const apiVersion = 'api';
const nodeId = '1fa85f64-5717-4562-b3fc-2c963f66afa2';
const projectId = '1fa85f64-5717-4562-b3fc-2c963f66afa1';
const customerId = '0c2cbe28-1b56-4a37-bfae-d1f49cf3cc36';
const proPortalNodeId = 'ae603f38-3867-473a-8030-0aaecb938dc1';

const API_BASE_URL = `${baseUrl}/${apiVersion}/node/${nodeId}/project/${projectId}`;
const API_BASE_URL_V1 = `${baseUrl}/${apiVersion}/v1/node/${nodeId}/project/${projectId}`;
const PRO_PORTAL_API_BASE_URL = `${baseUrl}/${apiVersion}/node/${proPortalNodeId}/customer/${customerId}`;
const PRO_PORTAL_API_PROJECT_CUSTOMER_URL = `${API_BASE_URL_V1}/customer/${customerId}`;

export const serviceUrl: any = {
  apiBaseURL: API_BASE_URL,
  proPortalApiBaseURL: PRO_PORTAL_API_BASE_URL,
  evaluationExists: `${API_BASE_URL}/evaluationexists`,
  evaluation: `${API_BASE_URL}/evaluation`,
  evaluationv1: `${API_BASE_URL_V1}/evaluation`,
  getMasterDataForEvaluation: `${PRO_PORTAL_API_BASE_URL}/getmasterdataforevaluation`,
  getErrorWarning: `${API_BASE_URL_V1}/evaluation`,
  evaluationStage: `${API_BASE_URL}/evaluationstage`,
  evaluationSection: `${API_BASE_URL}/evaluationsection`,
  evaluationCriteria: `${API_BASE_URL}/evaluationcriteria`,
  deleteStage: `${API_BASE_URL_V1}/evaluation/stage`,
  deleteSection: `${API_BASE_URL_V1}/evaluation/section`,
  deleteCriteria: `${API_BASE_URL_V1}/evaluation/criteria`,
  copyStage: `${API_BASE_URL_V1}/evaluation/copystage`,
  copySection: `${API_BASE_URL_V1}/evaluation/copysection`,
  copyCriteria: `${API_BASE_URL_V1}/evaluation/copycriteria`,
  proposalResponse: `${PRO_PORTAL_API_PROJECT_CUSTOMER_URL}/proposalresponse`,
};
