import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpXsrfTokenExtractor,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class CustomInterceptor implements HttpInterceptor {
  constructor(private tokenExtractor: HttpXsrfTokenExtractor) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = this.tokenExtractor.getToken() as string;
    request = request.clone({
      withCredentials: true,
      // setHeaders: { 'X-XSRF-TOKEN': token || '' },
      setHeaders: {
        'X-XSRF-TOKEN': token || '',
        externalaccessapikey: '248BB949-0F0F-4BCF-9031-78883464C97D',
      },
    });

    return next.handle(request);
  }
}
